<template>
  <div>
    <div class="filtering">
      <div class="search-toggle">
        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSearch === 'ArticleNumber' }"
            @click="toggleName('ArticleNumber')"
          >
            {{ $t('ArticleNumber') }}
          </p>
          <p
            :class="{ active2: toggleSearch === 'Barcode' }"
            @click="toggleName('Barcode')"
          >
            {{ $t('Barcode') }}
          </p>
        </div>
      </div>
      <div
        class="searchfilter"
        style="display: flex;justify-content: space-between;"
      >
        <div
          v-if="toggleSearch === 'ArticleNumber'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchArticle == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchArticle != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchArticle = ''"
              />
              <input
                v-model="searchArticle"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('ArticleNumber')"
                :style="getArticleNameLike.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getArticleNameLike.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getArticleNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getArticleNameLike"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByArticleName(result);"
              >
                <p style="margin: 0">
                  {{ result.articleNumber }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="toggleSearch === 'Barcode'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchBarcode == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchBarcode != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchBarcode = ''"
              />
              <input
                v-model="searchBarcode"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('Barcode')"
                :style="getArticleByBarcode.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChangedBarcode"
                @keydown.down="onArrowDownBarcode"
                @keydown.up="onArrowUpBarcode"
              >
            </div>
            <div
              v-if="getArticleByBarcode.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getArticleByBarcode.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getArticleByBarcode"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByBarcode(result);"
              >
                <p style="margin: 0">
                  {{ result.articleNumber }} - {{ result.barcode }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="display:flex;gap:10px;align-items: baseline; justify-content: flex-end;">
        <StoresDropdown
          :filiale="store"
          @updateFiliale="updateFiliale"
        />
      </div>
      <table
        class="team_table"
        style="margin-top:10px;"
      >
        <thead>
          <tr>
            <th>
              {{ $t('No') }}
            </th>
            <th>
              {{ $t('StoreName') }}
            </th>
            <th>
              {{ $t('Barcode') }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('Quantity') }}
            </th>
            <th>
              {{ $t('Add') }}
            </th>
            <th>
              {{ $t('Substract') }}
            </th>
            <th>
              {{ $t('Order') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getArticlesInCart"
            :key="index"
          >
            <td>
              {{ getTotalItemsArticleCart-(pageNumber-1)*pageSize-index }}.
            </td>
            <td>
              {{ item.storeName }}
            </td>
            <td>
              {{ item.barcode }}
            </td>
            <td>
              {{ item.orderedArticleNumber || item.articleNumber }}
            </td>
            <td>
              {{ item.quantity }}
            </td>
            <td>
              <div
                v-b-modal.add-substract-cart
                class="small-button"
                @click="addOrSubstract(item, true)"
              >
                <img
                  style="width: 25px; "
                  src="../../assets/images/plus.svg"
                  alt=""
                >
              </div>
            </td>
            <td>
              <div
                v-b-modal.add-substract-cart
                class="small-button"
                @click="addOrSubstract(item, false)"
              >
                <img
                  style="width: 25px; "
                  src="../../assets/images/minus.svg"
                  alt=""
                >
              </div>
            </td>
            <td>
              <button
                v-b-modal.order-articles-cart
                class="button"
                type="button"
                @click="orderArticlesModal(item)"
              >
                {{ $t('Order') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsArticleCart > 15"
          v-model="pageNumber"
          :total-rows="getTotalItemsArticleCart"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
      <AddSubstractArticle
        :object-cart="objectCart"
        @addOrSub="addOrSubstractArticle"
      />
      <OrderArticles
        :ordered-articles="orderedArticles"
        @orderNow="orderNowArticles"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StoresDropdown from '../StoresDropdown.vue';
import AddSubstractArticle from './modals/AddSubstractArticle.vue';
import OrderArticles from './modals/OrderArticles.vue';



export default {
  components: {
    StoresDropdown,
    AddSubstractArticle,
    OrderArticles,
  },
  props: [],
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
      store: { storeId: null, storeName: 'No filiale', storeGLN: null },
      toggleSearch: 'ArticleNumber',
      searchArticle: '',
      searchBarcode: '',
      searchBarcodeId: null,
      searchArticleId: null,
      arrowCounter: 0,
      enterPressed: false,
      skipWatch: false,
      objectCart: {
        quantity: 0,
        cartItemId: null,
        increaseQuantity: false,
      },
      orderedArticles: null,
    };
  },
  computed: {
    ...mapGetters([
      'getArticlesInCart',
      'getTotalItemsArticleCart',
      'getArticleNameLike',
      'getArticleByBarcode',
      'getAllStoresDropdown',
    ]),
  },
  watch: {
    pageNumber(value) {
      if (value) {
        this.loadArticlesInCart({
          storeId: this.store.storeId,
          orderState: 'Pending',
          pageNumber: value,
          pageSize: this.pageSize,
        });
        this.$emit('updatePageNumberCart', value)
      }
    },
    store(value) {
      if (value) {
        this.pageNumber = 1
        this.loadArticlesInCart({
          storeId: value.storeId,
          orderState: 'Pending',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.$emit('callStoreId', value)
      }
    },
    searchArticle(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists()
        } else if (!this.enterPressed) {
          this.productInStock_articleNumberLike(value)
        }
      }, 500)
    },
    searchBarcode(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.loadArticleByBarcode({
            barcodeLike: value,
          });
        }
      }, 500);
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'productInStock_articleNumberLike',
      'loadArticlesInCart',
      'resetProductInStock',
      'loadArticleByBarcode',
      'editCartArticleQuantity',
      'changeCartArticleState',
      'resetLoadBarcode',
    ]),
    orderNowArticles(value) {
      this.changeCartArticleState({
        cartItemId: value.cartItemId,
        newState: 'Ordered',
        object: null,
        successCallback: () => {
          this.loadArticlesInCart({
            storeId: this.store.storeId,
            orderState: 'Pending',
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        },
      });
    },
    async searchByBarcode(value) {
      this.loadArticlesInCart({
        storeId: this.store.storeId,
        inventoryProductId: value.inventoryId,
        orderState: 'Pending',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(() => {
        this.skipWatch = true;
        this.searchBarcode = value.barcode;
        this.searchBarcodeId = value.inventoryId;
        this.$emit('updateInventoryProduct', value.inventoryId)
        this.resetLoadBarcode()
      })
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByArticleName(this.getArticleNameLike[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleByBarcode.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedBarcode(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByBarcode(this.getArticleByBarcode[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    toggleName(value) {
      this.toggleSearch = value;
      this.searchArticle = '';
      this.searchBarcode = '';
      this.searchBarcodeId = null;
      this.searchArticleId = null;
      this.removeLists();
    },
    async searchByArticleName(value) {
      this.loadArticlesInCart({
        storeId: this.store.storeId,
        inventoryProductId: value.inventoryId,
        orderState: 'Pending',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(() => {
        this.skipWatch = true;
        this.searchArticle = value.articleNumber;
        this.searchArticleId = value.inventoryId;
        this.$emit('updateInventoryProduct', value.inventoryId)
        this.resetProductInStock();
      })
    },
    removeLists() {
      this.resetProductInStock()
      this.resetLoadBarcode()
      this.loadArticlesInCart({
        storeId: this.store.storeId,
        inventoryProductId: null,
        orderState: 'Pending',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
      this.searchBarcodeId = null;
      this.searchArticleId = null;
    },
    orderArticlesModal(value) {
      this.orderedArticles = value
    },
    addOrSubstractArticle(value) {
      this.editCartArticleQuantity({
        object: value,
        successCallback: () => {
          this.loadArticlesInCart({
            storeId: this.store.storeId,
            orderState: 'Pending',
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        },
      })
    },
    addOrSubstract(value, quantityValue) {
      this.objectCart = {
        quantity: value.quantity,
        cartItemId: value.cartItemId,
        increaseQuantity: quantityValue,
      }
    },
    updateFiliale(newFiliale) {
      this.store = newFiliale;
    },
  },
};
</script>

  <style scoped lang="scss">
    .team_table td {
      padding: 16px 9px !important;
    }
    .dropdrop {
      display: inline-block;
      overflow: auto;
      position: absolute;
      background: white;
      width: 227px;
      box-shadow: none;
      z-index:3;
    }

    input[name="search-box"] {
        display: block;
        max-width: 500px;
        width: 95%;
        padding: 0.5rem 1rem;
        padding-left: 25px;
    }
    .search-item {
        padding: 5px;
        max-width: 500px;
        width: 100%;
        font-weight: 400;
        color: #82868c;
        font-size: 1rem;
        border-bottom: 1px solid #e8e8e8;
    }
    .active2 {
      font-weight: bold;
      color: $base-color;
    }
    </style>
