<template>
  <div>
    <div>
      <div class="filtering">
        <div class="search-toggle">
          <p>{{ $t('SearchBy') }}</p>
          <div>
            <p
              :class="{ active2: toggleSearch === 'ArticleNumber' }"
              @click="toggleName('ArticleNumber')"
            >
              {{ $t('ArticleNumber') }}
            </p>
            <p
              :class="{ active2: toggleSearch === 'Barcode' }"
              @click="toggleName('Barcode')"
            >
              {{ $t('Barcode') }}
            </p>
          </div>
        </div>
        <div
          class="searchfilter"
          style="display: flex;justify-content: space-between;"
        >
          <div
            v-if="toggleSearch === 'ArticleNumber'"
            style="display: flex; align-items: center"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchArticle == ''"
                  class="search1"
                  style="position: absolute; left: 5px; top: 12px"
                />
                <b-icon-x-circle
                  v-else-if="searchArticle != ''"
                  class="search1"
                  style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                  @click="searchArticle = ''"
                />
                <input
                  v-model="searchArticle"
                  type="text"
                  autocomplete="off"
                  name="search-box"
                  :placeholder="$t('ArticleNumber')"
                  :style="getArticleNameLike.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getArticleNameLike.length > 0"
                ref="scrollContainer"
                class="dropdrop"
                :style="getArticleNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getArticleNameLike"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByArticleName(result);"
                >
                  <p style="margin: 0">
                    {{ result.articleNumber }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="toggleSearch === 'Barcode'"
            style="display: flex; align-items: center"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchBarcode == ''"
                  class="search1"
                  style="position: absolute; left: 5px; top: 12px"
                />
                <b-icon-x-circle
                  v-else-if="searchBarcode != ''"
                  class="search1"
                  style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                  @click="searchBarcode = ''"
                />
                <input
                  v-model="searchBarcode"
                  type="text"
                  autocomplete="off"
                  name="search-box"
                  :placeholder="$t('Barcode')"
                  :style="getArticleByBarcode.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                  @keyup="inputChangedBarcode"
                  @keydown.down="onArrowDownBarcode"
                  @keydown.up="onArrowUpBarcode"
                >
              </div>
              <div
                v-if="getArticleByBarcode.length > 0"
                ref="scrollContainer"
                class="dropdrop"
                :style="getArticleByBarcode.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getArticleByBarcode"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByBarcode(result);"
                >
                  <p style="margin: 0">
                    {{ result.articleNumber }} - {{ result.barcode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex;gap:10px;align-items: baseline; justify-content: space-between">
        <button
          v-b-modal.add-all-products
          class="button"
        >
          {{ $t("Cart") }} {{ orderItemIds.length == 0 ? '' : `: ${orderItemIds.length}` }}
        </button>
        <div style="display:flex;gap:30px;align-items: baseline; width: 50%; justify-content: flex-end;">
          <b-form-group :label="$t('FilterBasedOnQuantity')">
            <toggle-button
              :value="quantityFilter"
              :sync="true"
              color="#242F6E"
              @change="quantityFilter = !quantityFilter"
            />
          </b-form-group>
          <StoresDropdown
            :filiale="store"
            @updateFiliale="updateFiliale"
          />
        </div>
      </div>
      <p style="color:red;font-weight:600;margin-top: 15px;">
        {{ itemIsAlreadyOnCart }}
      </p>
      <table
        class="team_table"
        style="margin-top:10px;"
      >
        <thead>
          <tr>
            <th />
            <th>
              {{ $t('No') }}
            </th>
            <th>
              {{ $t('StoreName') }}
            </th>
            <th>
              {{ $t('Barcode') }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('Location') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th>
              {{ $t('Quantity') }}
            </th>
            <th>
              {{ $t('MinimalQuantity') }}
            </th>
            <th>
              {{ $t('fileUrls') }}
            </th>
            <th>
              {{ $t('AddProductCart') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getMerchandisePagination"
            :key="index"
          >
            <td>
              <input
                type="checkbox"
                :value="item.productInStockId"
                :checked="isItemChecked(item)"
                :disabled="(orderItemIds.length >= 15 && !isItemChecked(item)) || store.storeId == null"
                @change="event => handleCheckboxChange(event, item, index)"
              >
            </td>
            <td>
              {{ getTotalItemsMerchandise-(pageNumber-1)*pageSize-index }}.
            </td>
            <td>
              {{ item.storeName }}
            </td>
            <td>
              {{ item.barcode }}
            </td>
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ item.locationName }}
            </td>
            <td>
              {{ item.price }}
            </td>
            <td>
              {{ item.quantity || 0 }}
            </td>
            <td>
              {{ item.minimalQuantity || 0 }}
            </td>
            <td>
              <div
                v-for="(fileUrl, indexFile) in item.fileUrls"
                :key="indexFile"
              >
                <img
                  style="width: 50px; height: 50px;"
                  :src="fileUrl"
                >
              </div>
            </td>
            <td
              v-b-modal.add-product-cart
              style="text-align: left; cursor: pointer"
              @click="addOneProduct(item)"
            >
              <div>
                <p>
                  <span>
                    <b-icon-cart class="h4" />
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsMerchandise > 15"
          v-model="pageNumber"
          :total-rows="getTotalItemsMerchandise"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
      <AddProductToCart
        :one-product="oneProduct"
        @addOneProductOnly="addOneProductInCart"
      />
      <AddAllProductsToCart
        :order-item-ids="orderItemIds"
        @removeBool="removeBoolArticle"
        @addAllProductOnly="addAllProductOnlys"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StoresDropdown from '../StoresDropdown.vue';
import AddProductToCart from './modals/AddProductToCart.vue'
import AddAllProductsToCart from './modals/AddAllProductsToCart.vue';



export default {
  components: {
    StoresDropdown,
    AddProductToCart,
    AddAllProductsToCart,
  },
  props: [],
  data() {
    return {
      itemIsAlreadyOnCart: '',
      pageNumber: 1,
      pageSize: 15,
      store: { storeId: null, storeName: 'No filiale', storeGLN: null },
      toggleSearch: 'ArticleNumber',
      searchArticle: '',
      searchBarcode: '',
      searchBarcodeId: null,
      searchArticleId: null,
      arrowCounter: 0,
      enterPressed: false,
      skipWatch: false,
      quantityFilter: false,
      oneProduct: null,
      orderItemIds: [],
      selectAll: false,
    };
  },
  computed: {
    ...mapGetters([
      'getMerchandisePagination',
      'getTotalPagesMerchandise',
      'getTotalItemsMerchandise',
      'getArticleNameLike',
      'getArticleByBarcode',
      'getAllStoresDropdown',
      'getLoggedInUser',
    ]),
  },
  watch: {
    pageNumber(value) {
      if (value) {
        this.loadMerchandisePagination({
          storeId: this.store.storeId,
          inventoryProductId: this.searchArticleId || this.searchBarcodeId,
          applyStrickMerchandiseFilter: this.quantityFilter,
          pageNumber: value,
          pageSize: this.pageSize,
        });
        this.$emit('updatePageNumberStore', value)
        this.itemIsAlreadyOnCart = '';
      }
    },
    store(value) {
      if (value) {
        this.pageNumber = 1
        this.loadMerchandisePagination({
          storeId: value.storeId,
          inventoryProductId: this.searchArticleId || this.searchBarcodeId,
          applyStrickMerchandiseFilter: this.quantityFilter,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.$emit('callStoreIds', value)
        this.itemIsAlreadyOnCart = '';
      }
    },
    quantityFilter(value) {
      this.loadMerchandisePagination({
        storeId: this.store.storeId,
        inventoryProductId: this.searchArticleId || this.searchBarcodeId,
        applyStrickMerchandiseFilter: value,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
      this.$emit('callQuantityFilter', value)
      this.itemIsAlreadyOnCart = '';
    },
    searchArticle(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.productInStock_articleNumberLike(value);
        }
      }, 500);
      this.itemIsAlreadyOnCart = '';
    },
    searchBarcode(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.loadArticleByBarcode({
            barcodeLike: value,
          });
        }
      }, 500);
      this.itemIsAlreadyOnCart = '';
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'loadMerchandisePagination',
      'productInStock_articleNumberLike',
      'loadArticleByBarcode',
      'resetProductInStock',
      'resetLoadBarcode',
      'loadAllStoresByDropdown',
      'isArticleOnCart',
      'addItemsArticleCart',
      'loadArticlesInCart',
    ]),
    addOneProductInCart(value) {
      this.addItemsArticleCart({
        storeId: this.oneProduct.storeId,
        object: [value],
        successCallback: () => {
          this.selectAll = false
          this.orderItemIds = []
          this.itemIsAlreadyOnCart = '';
        },
      })
    },
    addAllProductOnlys(value) {
      this.addItemsArticleCart({
        storeId: this.store.storeId,
        object: value,
        successCallback: () => {
          this.selectAll = false
          this.orderItemIds = []
          this.itemIsAlreadyOnCart = '';
        },
      })
    },
    removeBoolArticle() {
      this.selectAll = false
    },
    async handleCheckboxChange(event, item, index) {
      const isInCart = await this.isArticleOnCart({
        storeId: item.storeId,
        inventoryProductId: item.inventoryProductId,
      });

      if (isInCart) {
        this.itemIsAlreadyOnCart = `${this.$t('ItemAlreadyOnCart')}`;
        event.target.checked = false;
      } else {
        this.toggleOrderItem(event, index);
        this.itemIsAlreadyOnCart = '';
      }
    },
    toggleOrderItem(event, index) {
      const item = this.getMerchandisePagination[index];
      const itemObject = {
        articleNumber: item.articleNumber,
        productInStockId: item.productInStockId,
        inventoryProductId: item.inventoryProductId,
        storeId: item.storeId,
        quantity: item.quantity,
        minimalQuantity: item.minimalQuantity || 0,
        newQuantity: 0,
        isCollapsed: true,
      };

      if (event.target.checked) {
        if (this.orderItemIds.length < 15) {
          this.orderItemIds.push(itemObject);
        }
      } else {
        const itemIndex = this.orderItemIds.findIndex(
          (i) => { return i.productInStockId === item.productInStockId },
        );
        if (itemIndex !== -1) {
          this.orderItemIds.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.orderItemIds.length === this.getMerchandisePagination.length;
    },

    isItemChecked(item) {
      return this.orderItemIds.some(
        (i) => { return i.productInStockId === item.productInStockId },
      );
    },

    addOneProduct(value) {
      this.oneProduct = value
      this.isArticleOnCart({
        storeId: value.storeId,
        inventoryProductId: value.inventoryProductId,
      });
    },
    updateFiliale(newFiliale) {
      this.store = newFiliale;
    },
    toggleName(value) {
      this.toggleSearch = value;
      this.searchArticle = '';
      this.searchBarcode = '';
      this.searchBarcodeId = null;
      this.searchArticleId = null;
      this.itemIsAlreadyOnCart = '';
      this.removeLists();
    },
    async searchByArticleName(value) {
      this.loadMerchandisePagination({
        storeId: this.store.storeId,
        inventoryProductId: value.inventoryId,
        applyStrickMerchandiseFilter: this.quantityFilter,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(() => {
        this.skipWatch = true;
        this.searchArticle = value.articleNumber;
        this.searchArticleId = value.inventoryId;
        this.$emit('updateInventoryProduct', value.inventoryId)
        this.resetProductInStock();
      });
    },
    async searchByBarcode(value) {
      this.loadMerchandisePagination({
        storeId: this.store.storeId,
        inventoryProductId: value.inventoryId,
        applyStrickMerchandiseFilter: this.quantityFilter,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(() => {
        this.skipWatch = true;
        this.searchBarcode = value.barcode;
        this.searchBarcodeId = value.inventoryId;
        this.$emit('updateInventoryProduct', value.inventoryId)
        this.resetLoadBarcode();
      });
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByArticleName(this.getArticleNameLike[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleByBarcode.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedBarcode(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByBarcode(this.getArticleByBarcode[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    removeLists() {
      this.resetProductInStock()
      this.resetLoadBarcode()
      this.loadMerchandisePagination({
        storeId: this.store.storeId,
        inventoryProductId: null,
        applyStrickMerchandiseFilter: this.quantityFilter,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
      this.searchBarcodeId = null;
      this.searchArticleId = null;
      this.itemIsAlreadyOnCart = '';
    },
  },
};
</script>

<style scoped lang="scss">
  .team_table td {
    padding: 16px 9px !important;
  }
  .dropdrop {
    display: inline-block;
    overflow: auto;
    position: absolute;
    background: white;
    width: 227px;
    box-shadow: none;
    z-index:3;
  }

  input[name="search-box"] {
      display: block;
      max-width: 500px;
      width: 95%;
      padding: 0.5rem 1rem;
      padding-left: 25px;
  }
  .search-item {
      padding: 5px;
      max-width: 500px;
      width: 100%;
      font-weight: 400;
      color: #82868c;
      font-size: 1rem;
      border-bottom: 1px solid #e8e8e8;
  }
  .active2 {
    font-weight: bold;
    color: $base-color;
  }
  </style>
