<template>
  <div>
    <b-modal
      id="add-all-products"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="`${$t('AddProductsCart')}`"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <div class="formGroup">
        <div
          v-for="(item, index) in orderItemIds"
          :key="index"
          class="orderItems"
        >
          <div style="display:flex;justify-content: space-between;">
            <span>
              {{ item.articleNumber }}
            </span>
            <div style="display:flex; gap:10px;">
              <b-icon-x-circle
                style="cursor:pointer;"
                @click="removeArticle(index)"
              />
              <b-icon-chevron-down
                v-if="!item.isCollapsed"
                style="cursor:pointer; margin-left: 10px;"
                @click="toggleCollapse(index)"
              />
              <b-icon-chevron-up
                v-if="item.isCollapsed"
                style="cursor:pointer; margin-left: 10px;"
                @click="toggleCollapse(index)"
              />
            </div>
          </div>
          <b-collapse
            :id="'collapse-' + index"
            :visible="item.isCollapsed"
            class="mt-2"
          >
            <div
              class="formGroup"
            >
              <b-form-group
                :label="$t('Quantity')"
              >
                <b-form-input
                  v-model.number="item.quantity"
                  type="number"
                  disabled
                  :placeholder="$t('Quantity')"
                />
              </b-form-group>
              <b-form-group
                :label="$t('MinimalQuantity')"
              >
                <b-form-input
                  v-model.number="item.minimalQuantity"
                  type="number"
                  disabled
                  :placeholder="$t('MinimalQuantity')"
                />
              </b-form-group>
              <b-form-group
                :label="$t('NewQuantity')"
              >
                <b-form-input
                  v-model.number="item.newQuantity"
                  type="number"
                />
              </b-form-group>
            </div>
          </b-collapse>
        </div>
      </div>
      <p
        v-if="orderItemIds.length == 0"
        style="color:red;"
      >
        {{ $t('NoArticlesSelected') }}
      </p>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css';

export default {
  components: {
  },
  props: ['orderItemIds'],
  data() {
    return {
      isButtonTrue: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAccountDetails',
      'getLocationsByStoreId',
    ]),
  },
  watch: {
    orderItemIds: {
      handler(newValue) {
        newValue.forEach((article, index) => {
          if (this.timeoutIds && this.timeoutIds[index]) {
            clearTimeout(this.timeoutIds[index]);
          }
          this.timeoutIds = this.timeoutIds || [];
          this.timeoutIds[index] = setTimeout(() => {
            if (article.newQuantity < 0 || article.newQuantity == '' || article.newQuantity == null) {
              article.newQuantity = 0
            }
          }, 100);
        });
      },
      deep: true,
    },
  },
  methods: {
    toggleCollapse(index) {
      this.orderItemIds[index].isCollapsed = !this.orderItemIds[index].isCollapsed;
    },
    removeArticle(index) {
      this.orderItemIds.splice(index, 1);
      this.$emit('removeBool')
    },
    onSubmit() {
      if (this.orderItemIds.length == 0) {
        return
      }
      const object = this.orderItemIds.map((item) => {
        return {
          productId: item.inventoryProductId,
          quantity: item.newQuantity,
        }
      })
      this.$emit('addAllProductOnly', object)
      this.resetForm()
    },
    onCancel() {
      this.resetForm()
    },
    resetForm() {
      this.$refs.modal.hide()
    },
  },
}
</script>

      <style lang="scss" scoped>
      .formGroup {
        display: grid;
         grid-template-columns: 1fr 1fr;
          gap: 10px;
      }
      .orderItems {
          padding:10px
          ;border:1px solid lightgrey;
          border-radius:12px;
          margin-top: 10px;
      }
      </style>
