<template>
  <div>
    <b-sidebar
      id="item-tracking"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="60%"
      :title="articleNumber"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ articleNumber }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div style="padding: 16px">
        <h3>{{ $t('StoreName') }}: {{ storeName }}</h3>
      </div>
      <table
        class="team_table"
      >
        <thead>
        <tr>
          <th>{{ $t('State') }}</th>
          <th>{{ $t('ExecutorName') }}</th>
          <th>{{ $t('Created') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(item, index) in getClickedArticleInHistory"
          :key="index"
        >
          <td>
            {{ $t(item.state) }}
          </td>
          <td>
            {{ item.executorName }}
          </td>
          <td>
            {{ formattedDate(item.created) }}
          </td>
        </tr>
        </tbody>
      </table>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment/moment';

export default {
  components: {
  },
  props: {
    articleNumber: {
      type: String,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['getClickedArticleInHistory']),
  },
  methods: {
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
    formattedDate(date) {
      return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 16px 9px !important;
}

.team_table tr {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  cursor: pointer;
}

.team_table thead {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  width: 600px !important;

  .tt {
    .vgt-table {
      th.sortable button {
        display: none !important;
      }
    }
  }

  .mats-listing {
    margin-top: 0;
  }
}

.button {
  margin-bottom: 15px !important;
  width: 195px;
}

.team_table {
  font-size: 12px;
  border-radius: 8px;
  width: 100%;
  box-shadow: none;
}
tr:last-child {
  border-bottom: none !important;
}

@media screen and (max-width: 1723px) {
  .mats-listing__wrapper {
    width: 400px !important;

    div {
      button {
        width: auto;
        height: 36px;
      }

      input {
        width: 160px;
      }

    }

    .table__search-input {
      padding-left: 0px;
      margin-left: 0px !important;
    }
  }

}

@media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 100% !important;
  }
}


.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
