<template>
  <div>
    <b-modal
      id="order-articles-cart"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('OrderNow')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <p>
        {{ $t('AreUSureArticle') }} {{ orderedArticles?.quantity }} {{ orderedArticles?.articleNumber || orderedArticles?.orderedArticleNumber }}
      </p>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';



export default {
  components: {
  },
  props: ['orderedArticles'],
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters([
    ]),
  },
  watch: {
  },
  methods: {
    ...mapActions([
    ]),
    async onSubmit() {
      this.$emit('orderNow', this.orderedArticles)
      this.onReset();
    },
    onCancel() {
      this.onReset();
    },
    onReset() {
      this.$refs.modal.hide();
    },
  },
};
</script>

    <style lang="scss" scoped>
    </style>
