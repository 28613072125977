<template>
  <div>
    <b-modal
      id="add-substract-cart"
      ref="modal"
      header-class="headerModal"
      centered
      :title="objectCart?.increaseQuantity == true ? $t('Increase') : $t('Decrease')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form-group
        :label="$t('Quantity')"
      >
        <b-form-input
          v-model="objectCart.quantity"
          disabled
        />
      </b-form-group>
      <b-form-group
        :label="objectCart?.increaseQuantity == true ? $t('AddQuantity') : $t('RemoveQuantity')"
      >
        <b-form-input
          v-model.number="newQuantity"
          type="number"
        />
      </b-form-group>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';



export default {
  components: {
  },
  props: ['objectCart'],
  data() {
    return {
      newQuantity: 0,
    };
  },

  computed: {
    ...mapGetters([
    ]),
  },
  watch: {
    newQuantity(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '' || value == null || value < 0) {
          this.newQuantity = 0
        }
      }, 100);
    },
  },
  methods: {
    ...mapActions([
    ]),
    async onSubmit() {
      const object = {
        cartItemId: this.objectCart.cartItemId,
        increaseQuantity: this.objectCart.increaseQuantity,
        quantity: this.newQuantity,
      }
      this.$emit('addOrSub', object)
      this.onReset();
    },
    onCancel() {
      this.onReset();
    },
    onReset() {
      this.$refs.modal.hide();
      this.newQuantity = 0
    },
  },
};
</script>

  <style lang="scss" scoped>
  </style>
