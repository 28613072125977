<template>
  <div>
    <b-modal
      id="add-product-cart"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AddProductCart')} ${oneProduct?.articleNumber}`"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <div v-if="getIsArticleOnCart">
        <p style="color:red;font-weight:600;">
          {{ $t('ItemAlreadyOnCart') }}
        </p>
      </div>
      <div v-else>
        <div style="display:flex;gap:10px;">
          <b-form-group
            :label="$t('Quantity')"
          >
            <b-form-input
              v-model="oneProduct.quantity"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('MinimalQuantity')"
          >
            <b-form-input
              v-model="oneProduct.minimalQuantity"
              disabled
            />
          </b-form-group>
        </div>
        <b-form-group
          :label="$t('NewQuantity')"
        >
          <b-form-input
            v-model.number="quantity"
            type="number"
          />
        </b-form-group>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </div>


    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';



export default {
  components: {
  },
  props: ['oneProduct'],
  data() {
    return {
      quantity: 0,
    };
  },

  computed: {
    ...mapGetters([
      'getIsArticleOnCart',
    ]),
  },
  watch: {
    quantity(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0 || value == '' || value == null) {
          this.quantity = 0
        }
      }, 100);
    },
    oneProduct(value) {
      if (value) {
        this.oneProduct.minimalQuantity = value.minimalQuantity || 0
      }
    },
  },
  methods: {
    ...mapActions([
    ]),
    async onSubmit() {
      const object = {
        productId: this.oneProduct.inventoryProductId,
        quantity: this.quantity,
      }
      this.$emit('addOneProductOnly', object)
      this.onReset();
    },
    onCancel() {
      this.onReset();
    },
    onReset() {
      this.$refs.modal.hide();
      this.quantity = 0
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
