<template>
  <div>
    <section class="main no-flex">
      <b-tabs>
        <b-tab @click="callMerchandise">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("MerchInStore") }}</strong>
          </template>
          <div>
            <ProductsListInStock
              @callStoreIds="updateStoreIds"
              @callQuantityFilter="updateQuantityFilter"
              @updateInventoryProduct="updateInventoryProducts"
              @updatePageNumberStore="updatePageNumberStores"
            />
          </div>
        </b-tab>
        <b-tab @click="callCart">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Cart") }} - {{ getTotalItemsArticleCart }}</strong>
          </template>
          <div>
            <ArticlesInCart
              @storeCartOrdered="updateStoreId"
              @updatePageNumberCart="updatePageNumberCarts"
            />
          </div>
        </b-tab>
        <b-tab @click="callOrdered">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Ordered") }} - {{ getTotalItemsArticleCartOrdered }}</strong>
          </template>
          <div>
            <ArticlesInOrdered
              @callStoreIdOrdered="updateStoreIdOrdered"
              @updatePageNumberOrdered="updatePageNumberOrdereds"
            />
          </div>
        </b-tab>
        <b-tab @click="callHistory">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("History") }} - {{ getTotalItemsHistory }}</strong>
          </template>
          <div>
            <ArticlesInHistory
              @callStoreIdHistory="updateStoreIdHistory"
              @updatePageNumberHistory="updatePageNumberHistory"
            />
          </div>
        </b-tab>
      </b-tabs>

    </section>

  </div></template>

<script>
import ProductsListInStock from '@/components/productsInStock/ProductsListInStock.vue'
import ArticlesInCart from '@/components/productsInStock/ArticlesInCart.vue'
import ArticlesInOrdered from '@/components/productsInStock/ArticlesInOrdered.vue'
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ArticlesInHistory from '@/components/productsInStock/ArticlesInHistory.vue';

export default {
  name: 'Home',
  components: {
    ArticlesInHistory,
    ProductsListInStock,
    ArticlesInCart,
    ArticlesInOrdered,
  },
  data() {
    return {
      storeCart: { storeId: null, storeName: 'No filiale', storeGLN: null },
      storeCartMerchandise: { storeId: null, storeName: 'No filiale', storeGLN: null },
      storeCartOrdered: { storeId: null, storeName: 'No filiale', storeGLN: null },
      storeCartHistory: { storeId: null, storeName: 'No filiale', storeGLN: null },
      historyStartDate: moment().startOf('month').format('YYYY-MM-DD'),
      historyEndDate: moment().format('YYYY-MM-DD'),
      pageNumberStore: 1,
      pageNumberCart: 1,
      pageNumberOrdered: 1,
      pageNumberHistory: 1,
      pageSize: 15,
      quantityFilter: false,
      inventoryProductId: null,
    }
  },
  computed: {
    ...mapGetters(['getTotalItemsArticleCart', 'getTotalItemsArticleCartOrdered', 'getTotalItemsHistory']),
  },
  watch: {
  },
  methods: {
    ...mapActions(['loadMerchandisePagination', 'loadArticlesInCart', 'loadArticlesInCartOrdered', 'loadArticlesInHistory']),
    updateStoreIds(value) {
      this.storeCartMerchandise = {
        storeId: value.storeId,
        storeName: value.storeName,
        storeGLN: value.storeGLN,
      }
    },
    updateStoreId(value) {
      this.storeCart = {
        storeId: value.storeId,
        storeName: value.storeName,
        storeGLN: value.storeGLN,
      }
    },
    updateStoreIdOrdered(value) {
      this.storeCartOrdered = {
        storeId: value.storeId,
        storeName: value.storeName,
        storeGLN: value.storeGLN,
      }
    },
    updateStoreIdHistory(value) {
      this.storeCartHistory = {
        storeId: value.storeId,
        storeName: value.storeName,
        storeGLN: value.storeGLN,
      }
    },
    updateQuantityFilter(value) {
      this.quantityFilter = value
    },
    updateInventoryProducts(value) {
      this.inventoryProductId = value
    },
    updatePageNumberStores(value) {
      this.pageNumberStore = value
    },
    updatePageNumberCarts(value) {
      this.pageNumberCart = value
    },
    updatePageNumberOrdereds(value) {
      this.pageNumberOrdered = value
    },
    updatePageNumberHistory(value) {
      this.pageNumberHistory = value
    },
    callMerchandise() {
      this.loadMerchandisePagination({
        storeId: this.storeCartMerchandise.storeId,
        inventoryProductId: this.inventoryProductId,
        applyStrickMerchandiseFilter: this.quantityFilter,
        pageNumber: this.pageNumberStore,
        pageSize: this.pageSize,
      });
    },
    callCart() {
      this.loadArticlesInCart({
        storeId: this.storeCart.storeId,
        orderState: 'Pending',
        pageNumber: this.pageNumberCart,
        pageSize: this.pageSize,
      });
    },
    callOrdered() {
      this.loadArticlesInCartOrdered({
        storeId: this.storeCartOrdered.storeId,
        orderState: 'Ordered',
        pageNumber: this.pageNumberOrdered,
        pageSize: this.pageSize,
      });
    },
    callHistory() {
      this.loadArticlesInHistory({
        storeId: this.storeCartHistory.storeId,
        startDate: this.historyStartDate,
        endDate: this.historyEndDate,
        pageNumber: this.pageNumberHistory,
        pageSize: this.pageSize,
      })
    },
  },
}
</script>

<style scoped lang="scss">
 @media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
    justify-content: center;
  }
 }
 @media screen and (max-width: 780px) {
  .main {
    .flex {
      flex-direction: column;
      .color-listing__wrapper {
        margin-left: 0;
      }
    }
  }
 }
</style>
