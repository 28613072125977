import { render, staticRenderFns } from "./ArticlesInOrdered.vue?vue&type=template&id=5f4c94a2&scoped=true"
import script from "./ArticlesInOrdered.vue?vue&type=script&lang=js"
export * from "./ArticlesInOrdered.vue?vue&type=script&lang=js"
import style0 from "./ArticlesInOrdered.vue?vue&type=style&index=0&id=5f4c94a2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f4c94a2",
  null
  
)

export default component.exports